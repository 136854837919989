<template>
  <div class="authority">
    <div class="banner">
      <img src="https://img.irisgem.com.cn//pc/qwrzbg.jpg" alt />
    </div>
    <div class="search">
      <input type="text" placeholder="请输入订单编号" v-model="orderno" @keydown.enter="search" />
      <div @click="search">
        <i class="el-icon-search"></i>
        <span>搜索</span>
      </div>
    </div>

    <div class="container">
      <div>
        <div class="title-en">CERTIFICATION AUTHORUTY</div>
        <div class="title-zh">权威认证</div>
        <div class="certificate">
          <div class="img">
            <img src="https://img.irisgem.com.cn/phone/qwrz/renzheng.jpg" alt />
          </div>
          <div class="txt">
            <div class="title">碳源证书</div>
            <div
              class="desc"
            >每一颗伊莉丝钻石都是由宝宝的胎发碳化而来的高纯度碳粉在经历数日持续不断高温高压的生长环境后培育而成。它具有独一无二的属性，每一颗都有属于它自己的碳源证书</div>
            <div class="btn" @click="orderDimond">定制伊莉丝钻石</div>
          </div>
        </div>
      </div>

      <div class="title-en">OTHER CERTIFICATION</div>
      <div class="title-zh">其他证书</div>
      <div class="other-certificate">
        <swiper :options="swiperOption2" class="swiper3" style="height:8rem;">
          <swiper-slide>
            <div class="other-item">
              <div class="img" @click="igi()">
                <img src="https://img.irisgem.com.cn//wxxcx/qwrz/q2.jpg" alt />
              </div>
              <div class="title" @click="igi()">IGI证书</div>
              <div
                class="desc"
              >国际宝石学院IGI(InternationalGemologicalInstitute)成立于1975年的世界钻石中心比利时的安特卫普，是目前世界上最大的独立珠宝首饰鉴定实验室，在全球各大钻石交易中心(安特卫普，纽约，多伦多，迪拜，东京，香港，特拉维夫，洛杉矶，孟买)共设有15个实验室，被称为"消费者身边的权威鉴定所"。IGI目前是全球最大的独立实验室，全球专家成员达到800名，分布世界十余个国家......</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="other-item">
              <div class="img" @click="cti()">
                <img src="https://img.irisgem.com.cn//wxxcx/qwrz/q4.jpg" alt />
              </div>
              <div class="title" @click="cti()">CTI证书</div>
              <div
                class="desc"
              >华测检测认证集团股份有限公司（英文"Centre Testing International Group Co., Ltd."，简称"CTI"）作为中国第三方检测与认证服务的开拓者和领先者，是一家集检测、校准、检验、认证及技术服务为一体的综合性第三方机构，在全球范围内为企业提供一站式解决方案。在全国拥有六十多个分支机构，近130个实验室，并在中国台湾、中国香港、美国、英国、新加坡等地设立了海外办事机构......</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="other-item">
              <div class="img" @click="gia()">
                <img src="https://img.irisgem.com.cn//wxxcx/qwrz/q3.jpg" alt />
              </div>
              <div class="title" @click="gia()">GIA证书</div>
              <div
                class="desc"
              >美国宝石学院(Gemological Institute of America简称GIA)由Robert M.Shipley先生于1931年创立，至今GIA在全球已有十四个教学机构，提供专业的研究、销售、鉴定等课程，培养高素质的珠宝从业人员，也促成GIA在业界有此崇高的声誉，让大众对它的信赖度与专业性始终保持领导世界的地位，如今GIA提出的4C标准也是国际珠宝界的通用标准。GIA是非营利机构，经费由珠宝业界人士捐献，主要服务范围在珠宝鉴定及专业知识的教育与研究，深受全球珠宝业的认同。GIA是把钻石鉴定证书推广成为国际化的创始者。</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="btn center-btn" @click="orderDimond">定制伊莉丝钻石</div>
    </div>
    <div class="swips">
    <div class="swiper-container">
      <div class="swiper-title-zh">信赖之声</div>
      <div class="swiper-title-en">CUSTOMER COMPLIMENT</div>
      <div class="quot"></div>

      <swiper class="swiper" ref="swiper" :options="swiperOption">
        <swiper-slide class="swiper-slide" v-for="(item, index) in list" :key="index">
          <div class="swiper-conten">{{ item }}</div>
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 左右箭头 -->
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-swiper" :options="swiperOption1">
        <swiper-slide v-for="item in swipe" :key="item.id" class="el-sp"   :style="{ backgroundImage: 'url(' + item.img + ')' }">
          <div class="swiper-conten">{{ item.name }}</div>
        </swiper-slide>
      </swiper>

      <div class="zhedang"></div>
    </div>
  </div>
    <div class="leave-message">
      <div class="title-zh">用心倾听每一位客户的心声</div>
      <div class="title-en">LISTEN TO THE VOICE OF EVERY CUSTOMER</div>
      <div class="inputs">
        <div class="input">
          <span class="label">姓名：</span>
          <input type="text" v-model="name" />
        </div>
        <div class="input">
          <span class="label">手机号：</span>
          <input type="text" v-model="tel" />
        </div>
      </div>
      <div class="textarea">
        <span class="label">留言板：</span>
        <textarea v-model="leave"></textarea>
      </div>
      <div class="vertify">
        <span class="label">验证码：</span>
        <input type="text" v-model="code" />
        <img
          :src="'https://www.irisgem.cn/gemservers/cu/getVerifyCode?devicenum=' + num + '&a=' + timeblob"
          @click="timeblob = radum()"
          alt
        />
        <span class="change" @click="timeblob = radum()">换一张</span>
      </div>
      <div class="btn" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { GET_ORDERZS, msgconsulting as leave } from "../../service/order";
// import { LOGIN } from "../../service/login";
// import Cookies from "js-cookie";

export default {
  name: "Authority",
  components: {},
  data() {
    let that = this
    return {
      name: "",
      tel: "",
      leave: "",
      code: "",
      dindex: 1,
      orderno: "",
      swipe: [
        { id: 1, name: "Alice Blandino",img:'https://img.irisgem.com.cn//pcweb/qwrz/AliceBlandino.jpg' },
        { id: 2, name: "Amanda Azzolin" ,img:'https://img.irisgem.com.cn//pcweb/qwrz/AmandaAzzolin.jpg'},
        { id: 3, name: "三皮匠传媒CEO" ,img:'https://img.irisgem.com.cn//pcweb/qwrz/三皮匠传媒CEO.jpg'},
        { id: 4, name: "上泓文化CEO" ,img:'https://img.irisgem.com.cn//pcweb/qwrz/上泓文化CEO.jpg'},
        { id: 5, name: "新成咨询董事长Sing",img:'https://img.irisgem.com.cn//pcweb/qwrz/新成咨询董事长Sing.jpg' }
      ],
      swiperOption: {
        //显示分页
        // pagination: {
        //   el: ".swiper-pagination"
        // },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //自动轮播
        // autoplay: {
        //   delay: 3000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false
        // },
        //开启循环模式
        loop: true
      },
      swiperOption1: {
        //显示分页
        // pagination: {
        //   el: ".swiper-pagination"
        // },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: 5,
        spaceBetween:50,
        centeredSlides: true,
        loop: true,
        //自动轮播
        // autoplay: {
        //   delay: 3000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false
        // },
        //开启循环模式
        on:{
          resize:function(res){
            console.log('执行了')
            $(".el-sp").css('height',$('.el-sp').css('width'))
            if(document.body.clientWidth < 1200){
              $(".authority .swiper-container .swiper-swiper").attr('style','transform:translateX(-50%) scale(0.8) translate3d(0, 0, 0) translateY(10px);')
            }
          }
        },
      },
      list: [
        `伊莉丝个性化、定制化的客户服务，优质的方案提供和端到端的服务跟踪方式，领跑业界！`,
        "伊莉丝宽容的文化氛围，快乐的员工工作环境，值得业界企业学习！",
        "“关爱女性，关爱下一代”伊莉丝积极的企业社会责任形象毫无疑问业界同行的楷模。",
        "伊莉丝提供的C对B设计方案真正做到了将艺术家从幕后推向市场前沿的效果，由此产生的交互体验与传统饰品品牌定制设计体验感截然不同。",
      ],
      zs: null,
      timeblob: this.radum(),
      times: new Date().getTime(),
      radums: parseInt(Math.random() * 100 + 10),
      num: "",
      swiperOption2: {
        // autoplay: {
        //   delay: 2500,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: true
        // },
        slidesPerView: 2,
        loop: true,
        
        //  breakpoints: { 
        //     900: {  //当屏幕宽度大于等于320
        //       slidesPerView: 2,
        //       spaceBetween: 0,
        //       width:670,/* no */
        //     },
        //     1050: {  //当屏幕宽度大于等于768 
        //       slidesPerView: 2,
        //       spaceBetween: 0,
        //       width:710,/* no */
        //     },
        //     1120: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:720,/* no */
        //     },
        //     1180: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:740,/* no */
        //     },
        //     1207: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:770
        //     },
        //     1250: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:800
        //     },
        //      1300: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:820
        //     }, 
        //     1336: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:840
        //     },
        //      1368: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:870
        //     },
        //      1417: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:900
        //     }, 
        //     1462: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:940
        //     },
        //     1528: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:980
        //     },
        //     1584: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:1000
        //     },
        //     1620: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:1040
        //     },
        //     1685: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:1080
        //     },
        //     1700: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 30,
        //       width:1200
        //     },
        //     1800: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: -40,
        //     },
        //     1900: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: -20,
        //     },
        //     2000: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween: 60,
        //     },
        //     2100: {  //当屏幕宽度大于等于1280
        //       slidesPerView: 2,
        //       spaceBetween:170,
        //     }
        //   }
      }
    };
  },
  computed: {},
  created() {
    // LOGIN({ name: 18515222433, pwd: 123456 }).then(res => {
    //   Cookies.set("token", res.attachment.token);
    // });
  },
  methods: {
    submit() {
      var TEL_REGEXP = /^1[3456789]\d{9}$/;
      if (
        this.name == "" ||
        this.tel == "" ||
        this.leave == "" ||
        this.code == ""
      ) {
        this.$message({
          message: "不能为空",
          type: "warning"
        });
      }
      // else if(!(TEL_REGEXP.test(this.tel))){
      //    this.$message({
      //     message: '手机号输入错误',
      //     type: 'warning'
      //   });
      // }
      else {
        leave({
          username: this.name,
          mobile: this.tel,
          code: this.code,
          devicenum: this.times + this.radums,
          c: this.leave
        }).then(res => {
          console.log(res);
          if (res.status == 400) {
            return;
          } else {
            this.$message({
              message: "感谢你的反馈",
              type: "success"
            });
          }
        });
      }
    },
    igi() {
      window.open("https://www.igi.org/verify-your-report/ch");
    },
    cti() {
      window.open("http://www.cti-lux.com/index.php?ac=form&at=list&fgid=1");
    },
    gia() {
      window.open("https://www.gia.edu/report-check-landing");
    },
    hans(index) {
      console.log(index);
    },
    search() {
      
      if (this.orderno === ""){
        return this.$message({ message: "请填写编号或订单号" ,type: 'warning'});
      }else{
        GET_ORDERZS({
        orderno: this.orderno,
        uid: this.$store.getters["user/getUid"]
      }).then(res => {
        console.log(res);
        if (res.status == 401) {
          this.$router.push({
            path: "/createLogin.html?url=/authority.html"
          });
        } else {
          this.$store.commit("img", res.attachment.tyzs);
          this.$store.commit("show", true);
        }

        // var top = $("#sapp").scrollTop();
        // $("#sapp").on("scroll.unable", function() {
        //   $("#sapp").scrollTop(top);
        // });
      });
      }
     
    },
    radum() {
      let time = new Date().getTime();
      let radum = parseInt(Math.random() * 100 + 10);
      return time + radum;
    },
    orderDimond() {
      this.$router.push({
        path: "/production.html"
      });
    }
  },
  mounted() {
    this.num = this.times + this.radums;
    console.log(this.num);
      $(".el-sp").css('height',$('.el-sp').css('width'))
    if(document.body.clientWidth < 1200){
      $(".authority .swiper-container .swiper-swiper").attr('style','transform:translateX(-50%) scale(0.8) translate3d(0, 0, 0) translateY(10px);')
    }
  }
};
</script>
<style lang="less" scoped>
.swiper3{
  width: 1300px !important;
}
.swips{
  background: url('https://img.irisgem.com.cn//wxxcx/qwrz/q1.png');
  background-size:100% 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 1596px;
  height: 547px;
  margin: auto;
}
.swiper-button-prev {
  margin-top: -70px;
  margin-left: 15px;
  outline: none;
}
.swiper-button-next {
  margin-top: -70px;
  margin-right: 15px;
  outline: none;
}
.zhedang {
  width: 708px;
  height: 100px;
  position: absolute;
  left: 52%;
  transform: translateX(-55%);
  bottom: 60px;
  z-index: 21;
}
.el-warp {
  width: 408px;
  position: absolute;
  left: 55%;
  transform: translateX(-55%);
  bottom: 40px;
  z-index: 20;
  overflow: visible;
}
.el-carousel__item /deep/.el-carousel__mask {
  background: none;
}
.el-sp {
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  background-size:100% 100%;
  object-fit: cover;
  align-items: center;
  position: relative;
  justify-content: center;
  .swiper-conten {
    position: absolute;
    bottom: -30px;
    font-size: 12px !important;
  }
}
.shejis {
  width: 100%;
  height: 84px;
  border-radius: 50px;
  background: #fff;
}
.authority {
  position: relative;
  width: 100%;
  height: 100%;
  .banner {
    width: 1596px;
    height: 500px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .search {
    width: 800px;
    height: 46px;
    border: 2px solid #921d22; /*no*/
    display: flex;
    align-items: center;
    margin: 60px auto 100px;
    input {
      width: 636px;
      height: 100%;
      border: none;
      outline: none;
      color: #333;
      padding: 0 24px;
      font-size: 16px;
    }
    > div {
      flex: 1;
      color: #fff;
      height: 100%;
      background: #921d22;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin-right: 10 px;
        font-size: 22px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .container {
    margin-bottom: 200px;
    .title-en {
      font-size: 30px;
      line-height: 1;
      margin-bottom: 20px;
      color: #333;
      text-align: center;
    }
    .title-zh {
      font-size: 26px;
      line-height: 1;
      margin-bottom: 54px;
      color: #425283;
      text-align: center;
    }
    .btn {
      width: 360px;
      height: 80px;
      line-height: 80px;
      color: #fff;
      background: #921d22;
      font-size: 25px;
      cursor: pointer;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    .certificate {
      display: flex;
      justify-content: center;
      padding-top: 16px;
      margin-bottom: 150px;
      .img {
        width: 400px;
         height: 540px;
        &:hover {
          cursor: pointer;
        }
        img {
          width: 100%;
           height: 100%;
        }
      }
      .txt {
        width: 560px;
        height: 540px;
        margin-left: 90px;
        .title {
          padding: 90px 0 28px;
          font-size: 45px;
          line-height: 1;
          color: #333;
          &:hover {
            color: #921d22;
            cursor: pointer;
          }
        }
        .desc {
          line-height: 35px;
          font-size: 22px;
          color: #333;
          margin-bottom: 60px;
        }
      }
    }
    .other-certificate {
      margin: 0 auto;
      .other-item {
        width: 590px;
        height: 760px;
        box-shadow: 0px 4px 5px 2px #d6d6d6;
        margin: 0 auto;
        .img {
          width: 100%;
          height: 339px;
          &:hover {
            cursor: pointer;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
        .title {
          padding: 0 27px;
          line-height: 84px;
          font-size: 34px;
          color: #333;
          margin-top: 8px;
          &:hover {
            color: #921d22;
            cursor: pointer;
          }
        }
        .desc {
          padding: 0 27px;
          color: #999;
          font-size: 22px;
          line-height: 35px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          overflow: hidden;
        }
      }
    }
    .center-btn {
      margin: 88px auto 0;
    }
  }
  .other-certificate .swiper-container {
    background: #ffffff;
  }
  // .swiper-container:nth-child(1){

  // }
  .swiper-container {
    width: 1596px;
    height: 517px;
    margin: 0 auto;
    background-size: 100% 100%;
    position: relative;
    color: #fff;
    margin-bottom: 50px;
    text-align: center;
    .swiper-title-zh {
      padding-top: 60px;
      font-size: 30px;
    }
    .swiper-title-en {
      margin-top: 13px;
      font-size: 18px;
    }
    .quot {
      font-size: 60px;
      font-family: serif;
      height: 100px;
    }
    .swiper {
      width: 1186px;
      height: 220px;
    }
    .swiper-swiper {
      width: 650px; /* no */
      color: #333333;
      height: 148px; /* no */
      position: absolute;
      bottom:0;
      left:50%;
      transform: translateX(-50%);
      z-index: 99;
      box-sizing: border-box;
      padding-top: 20px;/* no */
      margin-bottom:0;
    }
    .swiper-conten {
      width: 996px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 30px;
      // white-space: pre-line;
      color: #fff;
    }
  }
  .leave-message {
    width: 1100px;
    margin: 0 auto;
    padding-bottom: 190px;
    .title-zh {
      text-align: center;
      font-size: 29px;
      line-height: 58px;
      color: #333;
    }
    .title-en {
      text-align: center;
      font-size: 18px;
      line-height: 1;
      color: #999;
      margin-bottom: 48px;
    }
    .inputs {
      display: flex;
      justify-content: space-between;
      margin: 0;
      margin-bottom: 40px;
      > div {
        width: 500px;
        display: flex;
        align-items: center;
      }
    }
    .textarea {
      display: flex;
    }
    input {
      border: 1px solid #ccc; /*no*/
      outline: none;
      height: 16px;
      font-size: 16px;
      color: #333;
      flex: 1;
      padding: 8px 18px;
    }
    textarea {
      border: 1px solid #ccc; /*no*/
      outline: none;
      height: 210px;
      font-size: 16px;
      line-height: 1.5;
      color: #333;
      flex: 1;
      padding: 0 18px;
      resize: none;
    }
    .label {
      text-align: right;
      min-width: 90px; /*no*/
      width: 100px;
      padding-right: 28px;
      font-size: 15px;
      box-sizing: border-box;
    }
    .btn {
      width: 430px;
      height: 48px;
      line-height: 48px;
      color: #fff;
      background: #921d22;
      font-size: 18px;
      cursor: pointer;
      margin-top: 70px;
      text-align: center;
      margin: 0 auto;
    }
    .vertify {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0 70px;
      input {
        width: 134px;
        flex: 0;
        margin-right: 24px;
      }
      img {
        width: 80px; /* no */
        height: 36px; /* no */
        margin-right: 16px;
      }
      .change {
        font-size: 15px;
        color: #ccc;
      }
    }
  }
}
.swiper-swiper .swiper-slide-active {
  transform: scale(1.2);
  transition: all 300ms;
  position: relative;
  top: -10px;
}
.swiper-swiper .swiper-slide {
  width: 90px !important; /* no */
  height: 90px !important;/*no*/
}
</style>
